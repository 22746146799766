import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { Tag } from "@mui/icons-material";

const publicationData = [
  {
    img: "stateOfHate.jpg",
    link: "https://doi.org/10.1111/soin.12561",
    tag: "Publication",
    title:
      "The Reconstruction of the Cosmopolitan Imaginary: Chinese International Students during the COVID-19 Pandemic",
    description:
      "Social and geopolitical disruptions triggered by the COVID-19 crisis have raised crucial questions about the shifting meaning of race, citizenship, and nationality for transborder migrants amidst receding globalization, hardening borders, and geopolitical tensions.",
    authors: [
      {
        name: "Qing TingTing Liu",
        avatar: "/profile-picture.jpeg",
        date: "July 18, 2023",
      },
      { name: "Angie Y. Chung", avatar: "/angie-chung.jpeg" },
    ],
  },

  {
    img: "searchingForSweetness.jpg",
    tag: "Book Review",
    title: "Searching for Sweetness: Women's Mobile Lives in China and Lesotho",
    link: "https://journals.sagepub.com/doi/full/10.1177/01979183221131804?fbclid=IwAR0ONhLnYGHpK4DlWGd- yV oqnN5_49a5EUV aNhtjb9oOQJBMOcLRayW9FzY",
    description:
      "My review of Sarah Hanisch's book, Searching for Sweetness: Women's Mobile Lives in China and Lesotho, Published in International Migration Review",
    authors: [
      {
        name: "Qing TingTing Liu",
        avatar: "/profile-picture.jpeg",
        date: "December 12, 2022",
      },
    ],
  },
  {
    tag: "Book Chapter",
    link: "https://rowman.com/ISBN/9781498574259/Women-Social-Change-and-Activism-Then-and-Now",
    img: "museumSouthKorea.jpg",
    title:
      "The split subjectivity of female migrants born in the 1990s in China. Chapter 8.",
    authors: [
      {
        name: "Qing TingTing Liu",
        avatar: "/profile-picture.jpeg",
        date: "November 01, 2018",
      },
    ],
    description:
      "This chapter examines the split subjectivity of female migrants born in the 1990s in China. It explores how the split subjectivity is formed by the interaction between the migrants' personal experiences and the social structures they encounter in China and the host country.",
  },
  {
    tag: "Publication",
    link: "https://www.tandfonline.com/doi/abs/10.1080/12259276.2017.1381299",
    img: "ewhaWoman.jpg",
    title:
      "The experience of female migrants in an urban village of mainland China: A case study of self- help groups",
    authors: [
      {
        name: "Qing TingTing Liu",
        avatar: "/profile-picture.jpeg",
        date: "November 07, 2017",
      },
    ],
    description:
      "This study is based on my field experience and adopts a feminist perspective to examine the discourse and practice of self-help groups of women migrants.",
  },
];

const mediaEngagementData = [
  {
    tag: "Media Engagement",
    link: "https://www.sixthtone.com/news/1011346/how-chinese-students-abroad-navigate-race-and-nationality",
    img: "stopHate.png",
    title: "How Chinese Students Abroad Navigate Race and Nationality",
    authors: [
      {
        name: "Qing TingTing Liu",
        avatar: "/profile-picture.jpeg",
        date: "October 07, 2022",
      },
    ],
    description:
      "An article published on Sixth Tone, discussing how Chinese students abroad navigate race and nationality.",
  },
  {
    tag: "Media Engagement",
    link: "https://alphakappadelta.org/meet-the-authors-qing-tingting-liu-and-and-professor-angie-chung/",
    img: "graduation.png",
    title: "Interview with ακδ",
    authors: [
      {
        name: "Qing TingTing Liu",
        avatar: "/profile-picture.jpeg",
        date: "August 27, 2024",
      },
      { name: "Angie Y. Chung", avatar: "/angie-chung.jpeg" },
    ],
    description:
      "An interview with ακδ, an international society for sociology, discussing my research on Chinese international students during the COVID-19 pandemic.",
  },
  {
    tag: "Media Engagement",
    link: "https://www.youtube.com/watch?v=GaHi98cUmCs",
    img: "interview.png",
    title: "Culture in the Crosshairs: Countering a Surge of Hate",
    authors: [
      {
        name: "Qing TingTing Liu",
        avatar: "/profile-picture.jpeg",
        date: "May 14, 2022",
      },
    ],
    description: "Interviewed by WNYT NewsChannel 13.",
  },
  {
    tag: "Media Engagement",
    link: "https://m.thepaper.cn/newsDetail_forward_18773805?fbclid=IwAR1iNFfgk8IHL4S_oFabDgVR6zbI6oQmsNT_ EpfT7gU-cZ2c_2Xbq9KXM1A",
    img: "InternationGraduation.jpg",
    title: "疫情下的在美中国留学生:位置、身份认同与共同体的再想象.",
    authors: [
      {
        name: "Qing TingTing Liu",
        avatar: "/profile-picture.jpeg",
        date: "July 6th, 2022",
      },
    ],
    description: "The Paper 澎湃:思想市场",
  },
];

const SyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: "transparent",
    cursor: "pointer",
    opacity: 0.8,
    boxShadow: theme.shadows[2],
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 20%, 48%, 0.5)",
    outlineOffset: "2px",
  },
}));

const SyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  padding: 16,
  flexGrow: 1,
  "&:hover": {
    opacity: 0.8,
  },
  "&:last-child": {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

function Author({
  authors,
}: {
  authors: { name: string; avatar?: string; date?: string }[];
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
        }}
      >
        <AvatarGroup max={3}>
          {authors.map((author, index) => (
            <Avatar
              key={index}
              alt={author.name}
              src={author.avatar ?? ""}
              sx={{ width: 24, height: 24 }}
            />
          ))}
        </AvatarGroup>
        <Typography variant="caption">
          {authors.map((author) => author.name).join(", ")}
        </Typography>
      </Box>
      <Typography variant="caption">{authors[0].date}</Typography>
    </Box>
  );
}

export default function MainContent() {
  const [focusedCardIndex, setFocusedCardIndex] = React.useState<number | null>(
    null
  );

  const handleFocus = (index: number) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const handleClick = (link: string) => {
    window.open(link, "_blank");
  };
  const sortedCardData = publicationData.sort((a, b) => {
    return (
      new Date(b.authors[0].date ?? "").getTime() -
      new Date(a.authors[0].date ?? "").getTime()
    );
  });
  const sortedMediaEngagementData = mediaEngagementData.sort((a, b) => {
    return (
      new Date(b.authors[0].date ?? "").getTime() -
      new Date(a.authors[0].date ?? "").getTime()
    );
  });
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
    >
      <div style={{ display: "flex", flexDirection: "row", gap: "30%" }}>
        <Typography variant="h1" gutterBottom marginTop={4}>
          Qing TingTing Liu
        </Typography>
        <Box
          component="img"
          sx={{
            height: "40%",
            width: "30%",
            minHeight: "200px",
            minWidth: "200px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          alt="Qing TingTing Liu"
          src="/profile-picture.jpeg"
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "30%" }}>
        <Typography variant="h2" gutterBottom>
          About
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ minWidth: "300px" }}
        >
          I am a Ph.D. candidate in the Sociology Department at SUNY Albany,
          currently serving as a pre-doctoral fellow at the{" "}
          <a href="https://ccs.yale.edu/ccs-people/student-fellows/pre-doctoral-fellows#liu">
            Yale Center for Cultural Sociology,
          </a>{" "}
          a member of the{" "}
          <a href="https://arts.unimelb.edu.au/school-of-culture-and-communication/our-research/groups-and-resource-centre/asian-cultural-research-hub-acrh/our-members">
            Asian Cultural Research Hub at the University of Melbourne
          </a>{" "}
          and{" "}
          <a href="https://sites.google.com/view/aaas-socsci/home">
            Research and Social Media Coordinator for AAAS
          </a>
          . My research interests include global migration and transnationalism,
          diaspora politics, Asian and Asian American studies,
          intersectionality, culture, theory, and methodological
          cosmopolitanism.
        </Typography>
      </div>
      <Divider />
      <div style={{ display: "flex", flexDirection: "row", gap: "30%" }}>
        <Typography
          variant="h3"
          gutterBottom
          style={{ maxWidth: "110px", fontWeight: "bold" }}
        >
          Dissertation
          <Box
            component="img"
            sx={{
              height: "30%",
              width: "30%",
              minHeight: "200px",
              minWidth: "200px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            alt="Qing TingTing Liu"
            src="/Australia.jpg"
          />
          <Typography
            variant="subtitle1"
            style={{ fontStyle: "italic", minWidth: "200px" }}
          ></Typography>
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ minWidth: "300px" }}
        >
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            Chinese Working Holiday Makers in Australia:
          </Typography>{" "}
          My dissertation examines precarity and empowerment in transnational
          youth mobilities, investigating how factors such as{" "}
          <i>
            visa status, class, race, gender and sexuality, educational
            background, age,
          </i>{" "}
          and <i>nationality </i> shape migrants’ trajectories and their views
          on racism, multiculturalism, nationalism, and cosmopolitanism abroad.
          The methodology transcends "methodological nationalism" through a
          multi-sited ethnographic approach. From May to August 2023, I
          conducted intensive fieldwork in Australia, visiting nine cities:
          Sydney, Newcastle, Canberra, Adelaide, Perth, Melbourne, Cairns,
          Brisbane, and the Gold Coast. To compare differing decisions regarding
          “staying” or “returning,” I carried out a second phase of data
          collection focusing on working holiday makers who had returned to
          China. <br></br>
          <br></br>
          Preliminary findings from this project contribute to future
          comparative research in areas such as immigration policy across the
          United States, Canada, and Australia; how migrants from different
          socio-economic backgrounds experience racialization across diverse
          host countries; and how longitudinal methods can help understand
          shifts in perceptions of racism and empowerment over time, shaped by
          prolonged exposure to the host country’s social, cultural, and
          economic systems.
        </Typography>
      </div>
      <Divider />
      <div style={{ display: "flex", flexDirection: "row", gap: "30%" }}>
        <Typography
          variant="h3"
          gutterBottom
          style={{ maxWidth: "130px", fontWeight: "bold" }}
        >
          Teaching
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ minWidth: "300px" }}
        >
          Global Migration and Transnationalism, Introduction to Sociology,
          Social Inequality, Sociological Theory, Social Research Methods, Race
          and Ethnicity, Gender in Sociology, Intersectionality, Sociology of
          Culture
        </Typography>
      </div>
      <Divider />

      <Typography variant="h2" gutterBottom>
        Publications
      </Typography>
      <Grid container spacing={2} columns={12} sx={{ marginBottom: 4 }}>
        {sortedCardData.map((card, index) => (
          <Grid key={index} size={{ xs: 12, md: 6 }}>
            <SyledCard
              variant="outlined"
              tabIndex={0}
              className={focusedCardIndex === index ? "Mui-focused" : ""}
              onMouseEnter={() => handleFocus(index)}
              onMouseLeave={handleBlur}
              onClick={() => handleClick(card.link)}
            >
              <CardMedia
                component="img"
                alt={card.title}
                image={card.img}
                sx={{
                  aspectRatio: "16 / 9",
                  borderBottom: "1px solid",
                  borderColor: "divider",
                }}
              />
              <SyledCardContent>
                <Typography gutterBottom variant="caption" component="div">
                  {card.tag}
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  {card.title}
                </Typography>
                <StyledTypography
                  variant="body2"
                  color="text.secondary"
                  gutterBottom
                >
                  {card.description}
                </StyledTypography>
              </SyledCardContent>
              <Author authors={card.authors} />
            </SyledCard>
          </Grid>
        ))}
      </Grid>
      <Divider />
      <Typography variant="h2" gutterBottom>
        Media Engagement
      </Typography>
      <Grid container spacing={2} columns={12} sx={{ marginBottom: 4 }}>
        {sortedMediaEngagementData.map((card, index) => (
          <Grid key={index} size={{ xs: 12, md: 6 }}>
            <SyledCard
              variant="outlined"
              tabIndex={0}
              className={focusedCardIndex === index ? "Mui-focused" : ""}
              onMouseEnter={() => handleFocus(index)}
              onMouseLeave={handleBlur}
              onClick={() => handleClick(card.link)}
            >
              <CardMedia
                component="img"
                alt={card.title}
                image={card.img}
                sx={{
                  aspectRatio: "16 / 9",
                  borderBottom: "1px solid",
                  borderColor: "divider",
                }}
              />
              <SyledCardContent>
                <Typography gutterBottom variant="caption" component="div">
                  {card.tag}
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  {card.title}
                </Typography>
                <StyledTypography
                  variant="body2"
                  color="text.secondary"
                  gutterBottom
                >
                  {card.description}
                </StyledTypography>
              </SyledCardContent>
              <Author authors={card.authors} />
            </SyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
