import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { SvgIcon, Typography } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import BlueSkyLogo from "./BlueSkyLogo";
import Sitemark from "./SitemarkIcon";

// Keep your styled Toolbar as before
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: `calc(${theme?.shape?.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: "8px 12px",
}));

// Optional helper if you still need Link with Router
export const linkWithRouter = React.forwardRef((props: any, ref) => (
  <Link {...props} component={RouterLink} ref={ref} />
));

export default function AppAppBar() {
  // 1) State to toggle the small contact box
  const [showContactBox, setShowContactBox] = React.useState(false);

  const handleContactClick = (event: React.MouseEvent) => {
    // Prevent going to "/"
    event.preventDefault();
    setShowContactBox((prev) => !prev);
  };

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: "calc(var(--template-frame-height, 0px) + 28px)",
      }}
    >
      <Container>
        <StyledToolbar variant="dense" disableGutters>
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}
          >
            <Sitemark />
            <Box sx={{ display: "flex" }}>
              <Button
                variant="text"
                color="info"
                size="small"
                component={linkWithRouter}
                to="/"
              >
                Home
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                component={linkWithRouter}
                to="/cv"
              >
                CV
              </Button>

              {/* 2) Put Contact in its own container so we can position the box absolutely */}
              <Box sx={{ position: "relative" }}>
                <Button
                  variant="text"
                  color="info"
                  size="small"
                  onClick={handleContactClick}
                >
                  Contact
                </Button>

                {/* 3) Conditionally render the contact info box */}
                {showContactBox && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "calc(100% + 4px)", // just below the button
                      left: 0,
                      backgroundColor: alpha("#000", 0.6),
                      backdropFilter: "blur(8px)",
                      color: "#fff",
                      border: "1px solid #ccc",
                      borderRadius: 1,
                      p: 2,
                      zIndex: 9999,
                      minWidth: 200,
                    }}
                  >
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Professional:{" "}
                      <Link href="mailto:tliu20@albany.edu" color="inherit">
                        tliu20@albany.edu
                      </Link>
                      <br />
                      Personal:{" "}
                      <Link
                        href="mailto:liutingting0513@gmail.com"
                        color="inherit"
                      >
                        liutingting0513@gmail.com
                      </Link>
                    </Typography>
                    <Typography variant="body2"></Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box sx={{ gap: 1, alignItems: "center" }}>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{ justifyContent: "left", color: "text.secondary" }}
            >
              <IconButton
                color="inherit"
                size="small"
                href="https://scholar.google.com/citations?user=27bJMq8AAAAJ"
                aria-label="Google Scholar"
                sx={{ alignSelf: "center" }}
                title="Google Scholar"
              >
                <SchoolIcon />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                href="https://www.linkedin.com/in/qing-tingting-liu-251bb6181/"
                aria-label="LinkedIn"
                sx={{ alignSelf: "center" }}
                title="LinkedIn"
              >
                <LinkedInIcon />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                href="https://bsky.app/profile/bardjester.bsky.social"
                aria-label="BlueSky"
                sx={{ alignSelf: "center" }}
                title="BlueSky"
              >
                <SvgIcon>
                  <BlueSkyLogo />
                </SvgIcon>
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                href="https://www.facebook.com/profile.php?id=100005252685126"
                aria-label="Facebook"
                sx={{ alignSelf: "center" }}
                title="Facebook"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                href="https://x.com/bard_jester"
                aria-label="X"
                sx={{ alignSelf: "center" }}
                title="X"
              >
                <TwitterIcon />
              </IconButton>
            </Stack>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
